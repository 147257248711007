import { Preferences as Storage } from '@capacitor/preferences';
import api from '../../../api';

async function setToken(token) {
  await Storage.set({
    key: 'token',
    value: token,
  });
}

async function setUserData(userData) {
  userData.userId = userData.id;
  await Storage.set({
    key: 'user',
    value: JSON.stringify(userData),
  });
}

export default {
  login({}, userCredentials) {
    var form = new FormData();
    form.append("username", userCredentials.username);
    form.append("password", userCredentials.password);
    form.append("lastMerchant_id", userCredentials.lastMerchant_id);
    return api.post('/login', form)
      .then(async (response) => {
        if (response.status == 200) {
          const data = response.data;
          if (data.status == 0) {
            await setToken(data.data.token);
            await setUserData(data.data.user);
          }
  
          return response.data;
        }
        return '';
      })
      .catch((error) => error.response);
  },
};